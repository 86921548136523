import React, { useReducer, useState } from 'react';
// import addToMailchimp from 'gatsby-plugin-mailchimp'
import { graphql, Link, useStaticQuery } from "gatsby"
import showdown from "showdown"
import { saveSignupForm } from '../../services/save-signup-form';

const ResponseMessage = ({ response, type }) => (
  <div id="mce-responses" className={`response response--${type} ${ response ? '' : 'd-none'}`}>
    <div className="row">

      <div className={`response__text ${type === 'article' ? 'col-md-12' : 'col-md-5'}`}>
        <h5 className={`response__hedaline ${response?.result === 'success' ? 'text--success' : 'text--error'}`} dangerouslySetInnerHTML={{__html: response?.msg }}/>
        { response?.result === 'success' ? <p>Share this page with your colleagues and friends</p> : '' }
      </div>

      <div className={`response__ctas ${type === 'article' ? 'col-md-12' : 'col-md-7'}`}>
        {
          response?.result === 'success' 
            ?
              <>
                <div>
                  <Link to="/" className="button button--facebook">Share on Facebook</Link>
                </div>
                <div>
                  <Link to="/" className="button button--twitter">Share on Twitter</Link>
                </div>
                <div>
                  <Link to="/" className="button button--linkedin">Share on LinkedIn</Link>
                </div>
              </>

            : ''
        }
      </div>

    </div>
  </div> 
)

export default function SignUpForm ({ heading = 'Suscribe', type }) {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState();
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({...state, ...newState}),
    {
      FNAME: '',
      EMAIL: '',
      POSTCODE: '',
      CONSENT: 'yes'
    }
  );

  // = = = = = = = = = = = = =
  // GraphQL
  const data = useStaticQuery(graphql`
    query SignupForm {
      signupForm: markdownRemark(frontmatter: {form_title: {eq: "Signup Form"}}) {
        frontmatter {
          form_consent
          form_headline
          form_introduction
          thank_you_url
        }
      }
    }
  `);

  const hideElement = { 
    position: 'absolute',
    left: '-5000px',
  }

  // = = = = = = = = = = = = =
  // Utils

  // Convert Markdown to HTML
  const converter = new showdown.Converter();
  const htmlFormConsent = converter.makeHtml(data.signupForm.frontmatter.form_consent);
  const htmlFormThankYouUrl = data.signupForm.frontmatter.thank_you_url;

  // = = = = = = = = = = = = =
  // Event Listeners

  //
  const handleSubmit = async ( event ) => {
    event.preventDefault();
    setLoading(true);
    const response = await saveSignupForm(formInput)
    // displayResponse(response);
    htmlFormThankYouUrl !== undefined ? window.location.href = htmlFormThankYouUrl : displayResponse(response);
  }

  const displayResponse = (response) => {
    setResponse(response);
    setLoading(false);
    setTimeout( () => setResponse(null), 6000)
  }

  const handleChange = ( event ) => {
    const name = event.target.name;
    const newValue = event.target.value;
    setFormInput( { [name]: newValue } );
  }
  
  return (
    <div className="form--signup">
      <form onSubmit={ (e) => handleSubmit(e) } id="signup-form" name="signup-form" className="signup-form" target="_blank" noValidate>
          <div id="signup_scroll">

            <h2 className="form__heading">{ data.signupForm.frontmatter.form_headline }</h2>
            <div className='form__introduction' dangerouslySetInnerHTML={{__html: data.signupForm.frontmatter.form_introduction }}/>

            <div className="form-wrapper">
              <div className="row form__inputs">

                <div className={`${type === 'article' ? 'col-md-6' : 'col-md-3'}`}>
                  <div className="input-wrapper">
                    <input onChange={ (e) => handleChange(e) } type="text" value={ formInput.FNAME } placeholder="Name" name="FNAME" className="" id="mce-FNAME" required/>
                    <span className={`input__custom-label ${ formInput.FNAME ? 'show' : '' }`}>Name</span>
                  </div>
                </div>

                <div className={`${type === 'article' ? 'col-md-6' : 'col-md-3'}`}>
                  <div className="input-wrapper">
                    <input onChange={ (e) => handleChange(e) } type="email" value={ formInput.EMAIL } placeholder="Email" name="EMAIL" className ="required email " id="mce-EMAIL" required/>
                    <span className={`input__custom-label ${ formInput.EMAIL ? 'show' : '' }`}>Email</span>
                  </div>
                </div>

                <div className={`${type === 'article' ? 'col-md-6' : 'col-md-2'}`}>
                  <div className="input-wrapper">
                    <input onChange={ (e) => handleChange(e) } type="postcode" value={ formInput.POSTCODE } placeholder="Postcode" name="POSTCODE" className="required postcode " id="mce-POSTCODE" required/>
                    <span className={`input__custom-label ${ formInput.POSTCODE ? 'show' : '' }`}>Postcode</span>
                  </div>
                </div>
              
                <div className={`clear ${type === 'article' ? 'col-md-6' : 'col-md-2'}`}>
                  <div className="button button--submit">
                    {
                      loading !== true
                      ? <input readOnly type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" />
                      
                      : <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                    }
                  </div>
                </div>
                
              </div>

              <div className="form__consent">

                <div className="consent__text" dangerouslySetInnerHTML={{__html: htmlFormConsent }}/>

                <div className="consent__inputs">

                  <div className="radio-wrapper">
                    <input onChange={ (e) => handleChange(e) } id="yes" name="CONSENT" type="radio" value="yes" checked = { formInput.CONSENT === 'yes' ? true : false} />
                    <label htmlFor="yes">Yes</label>
                  </div>

                  <div className="radio-wrapper">
                    <input onChange={ (e) => handleChange(e) } id="no" name="CONSENT" type="radio" value="no" checked = { formInput.CONSENT === 'no' ? true : false} />
                    <label htmlFor="no">No</label>
                  </div>
                  
                </div>  

              </div>

              <ResponseMessage response={response} type={type} />
            </div>
               
          {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
          <div style={ hideElement } aria-hidden="true"><input readOnly type="text" name="b_6849c11e948014ac2699c7864_cd9e26563d" tabIndex="-1" value="" /></div>
        </div>
      </form>
    </div>
  )
}